<template>
	<div class="page page-scientific-challenges">
		<h2 class="is-title is-inverted">{{ title }}</h2>
		<div class="page-background" :style="{ backgroundImage: backgroundUrl }"></div>
		<div class="page-description">{{ subtitle }}</div>
		<div class="container">
			<div class="scientific-challenges">
				<div v-for="banner in banners" :key="banner.type" class="scientific-challenges__banner" :class="{'is-active': banner.active }">
					<a :href="banner.active && banner.link" target="_blank">
						<img :src="banner.banner | imageUrl" :alt="banner.name" />
					</a>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import $http from '../utils/http';
	import meta from '../utils/meta';
	import VirtualBagModal from '../components/VirtualBagModal';

	export default {
		name: 'ScientificChallenges',
		data() {
			return {
				title: '',
				subtitle: '',
				background: '',
				banners: [],
			};
		},
		computed: {
			backgroundUrl() {
				return this.background && this.background.url ? `url('${this.$options.filters.imageUrl(this.background)}')` : null;
			},
		},
		beforeRouteEnter(to, from, next) {
			$http.all([$http.get('page-scientific-challenge'), $http.get(`/scientific-challenges`)]).then(
				$http.spread((page, data) => {
					next(vm => {
						vm.setPage(page);
						vm.setData(data);
					});
				})
			);
		},
		beforeRouteUpdate(to, from, next) {
			$http.all([$http.get('page-scientific-challenge'), $http.get(`/scientific-challenges`)]).then(
				$http.spread((page, data) => {
					this.setPage(page);
					this.setData(data);
					next();
				})
			);
		},
		methods: {
			setPage({ data }) {
				if (data.title) {
					this.title = data.title;
				}
				meta.setTitle(this.title);
				this.subtitle = data.subtitle;
				this.background = data.background;
			},
			setData(data) {
				this.banners = data.data.sort((a, b) => (b.order !== null) - (a.order !== null) || a.order - b.order);
			},
		},
	};
</script>

<style scoped></style>
