<template>
	<div class="user-page__wrapper">
		<div class="user-page">
			<UserMenu />
			<div class="user-page__content recommendations">
				<h2>Recommendations</h2>
				<br />

				<section v-if="exhibitors.length === 0 && sessions.length === 0" class="has-text-centered recommendations__empty">
					No recommendations found.
				</section>

				<!-- Exhibitors -->
				<section v-if="exhibitors.length > 0">
					<h3 class="recommendations__section-title">Exhibitors</h3>
					<ul class="recommendations__list">
						<li v-for="exhibitor in exhibitors.slice(0, 3)" :key="exhibitor.id" class="recommendations__item exhibitor">
							<a v-if="exhibitor.slug" :href="`/exhibition/${exhibitor.slug}`" class="exhibitor__link" @click.prevent="goToBooth(exhibitor)">
								<img v-if="exhibitor.logo && exhibitor.logo.url" :src="exhibitor.logo | imageUrl" :alt="exhibitor.title" class="exhibitor__image" />
								<span v-if="!exhibitor.logo || (exhibitor.exhibitor_group && exhibitor.exhibitor_group.nas)">{{ exhibitor.title }}</span>
							</a>
							<span v-else class="exhibitor__image-wrapper" @click.prevent="goToBooth(exhibitor)">
								<img v-if="exhibitor.logo && exhibitor.logo.url" :src="exhibitor.logo | imageUrl" :alt="exhibitor.title" class="exhibitor__image" />
								<span v-if="!exhibitor.logo || (exhibitor.exhibitor_group && exhibitor.exhibitor_group.nas)">{{ exhibitor.title }}</span>
							</span>
						</li>
					</ul>
				</section>

				<!-- Sessions -->
				<template v-for="category in categories">
					<section v-if="categories.length > 0 && sessionInCategory(category.id).length > 0" :key="category.id">
						<h3 class="recommendations__section-title">{{ category.title }}</h3>
						<div :ref="`slider-${category.id}`" class="recommendations__list recommendations__list--slider glide">
							<div class="glide__track recommendations__slider-inner" data-glide-el="track">
								<ul class="glide__slides">
									<li v-for="session in sessionInCategory(category.id)" :key="session.id" ref="sessionItem" class="glide__slide" @click="openSession(session)">
										<AgendaSessionBox :session="session" view="small" :alt-title="false" :starred="true" />
									</li>
								</ul>
							</div>
							<div
								class="glide__arrows"
								data-glide-el="controls"
								:class="{ 'glide__arrows--2': sessionInCategory(category.id).length > 2, 'glide__arrows--1': sessionInCategory(category.id).length === 2 }"
							>
								<button class="glide__arrow glide__arrow--left" data-glide-dir="<">
									<inline-svg :src="require('../assets/arrow.svg')" width="16"></inline-svg>
								</button>
								<button class="glide__arrow glide__arrow--right" data-glide-dir=">">
									<inline-svg :src="require('../assets/arrow.svg')" width="16"></inline-svg>
								</button>
							</div>
						</div>
					</section>
				</template>

				<div class="recommendations__footer">
					<p>All recommendations are based on your profile interests.</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import UserMenu from '../components/UserMenu';
	import AgendaSessionBox from '../components/AgendaSessionBox';
	import Glide from '@glidejs/glide';
	import $http from '../utils/http';
	import platformSettings from '../utils/platformSettings';
	import ExhibitionOff from '../components/ExhibitionOff';
	import AgendaSessionModal from '../components/AgendaSessionModal';

	export default {
		name: 'Recommendations',
		components: {
			UserMenu,
			AgendaSessionBox,
		},
		data() {
			return {
				exhibitors: [],
				sessions: [],
				categories: [],
				platformSettings: platformSettings.state,
				sliderOptions: {
					type: 'slider',
					perView: 2,
					gap: 40,
					rewind: false,
					breakpoints: {
						1240: {
							perView: 1,
						},
						1023: {
							perView: 2,
						},
						700: {
							perView: 1,
						},
					},
				},
			};
		},
		beforeRouteEnter(to, from, next) {
			$http.all([$http.get('/session-categories'), $http.get(`/users-recommendations`)]).then(
				$http.spread((categories, recommendations) => {
					next(vm => {
						vm.setCategories(categories);
						vm.setRecommendations(recommendations);
					});
				})
			);
		},
		beforeRouteUpdate(to, from, next) {
			$http.all([$http.get('/session-categories'), $http.get(`/users-recommendations`)]).then(
				$http.spread((categories, recommendations) => {
					this.setCategories(categories);
					this.setRecommendations(recommendations);
					next();
				})
			);
		},
		methods: {
			setCategories(categories) {
				this.categories = categories.data;
			},
			setRecommendations(recommendations) {
				this.exhibitors = recommendations.data.exhibitors;
				this.sessions = recommendations.data.sessions;

				this.$nextTick(() => {
					this.initializeSlider();
				});
			},
			initializeSlider() {
				this.categories.forEach(category => {
					if (this.$refs['slider-' + category.id]) {
						this.glide = new Glide(this.$refs['slider-' + category.id][0], this.sliderOptions).mount();
					}
				});
			},
			sessionInCategory(categoryId) {
				return this.sessions.filter(item => {
					return item.session_category?.id === categoryId;
				});
			},
			goToBooth(exhibitor) {
				if (this.platformSettings.exhibitionExchange === false) {
					this.$modal.show(
						ExhibitionOff,
						{},
						{
							classes: 'login-modal',
							width: '90%',
							maxWidth: 550,
							height: 'auto',
							adaptive: true,
						}
					);
				} else {
					this.$router.push({ path: `/exhibition/${exhibitor.slug}` });
				}
			},
			openSession(session) {
				if (session.visitable) {
					this.$gtm.dataLayer().push({
						event: 'gaEvent',
						eCategory: 'Programme Agenda Event',
						eAction: session.session_category ? session.session_category.title + ' - Recommendations' : 'Recommendations',
						eLabel: session.title,
						Exhibitor: session.session_ga ? session.session_ga : '(not set)',
						Booth: '(not set)',
					});
					this.$modal.show(
						AgendaSessionModal,
						{
							session,
							altTitle: this.altTitle,
							starred: this.starred,
						},
						{
							classes: 'agenda-session-modal',
							width: '90%',
							maxWidth: 800,
							height: 'auto',
							adaptive: true,
						}
					);
				}
			},
		},
	};
</script>

<style scoped></style>
